<template>
  <router-view />
</template>

<script lang="ts">
import { computed } from 'vue';
//import { http, HttpResponse } from 'msw';
//import { setupWorker } from 'msw/browser';
import { useCounterStore } from 'stores/MainStore';
import { BaseStore } from 'stores/MainStore';
import { storeToRefs } from 'pinia';

//process.env.LANGCHAIN_TRACING_V2 = 'true';
//process.env.LANGCHAIN_PROJECT = 'VisualAgents';
//process.env.LANGCHAIN_ENDPOINT = 'https://api.smith.langchain.com';
//process.env.LANGCHAIN_API_KEY = '<YOUR-API-KEY>'; // Replace with your API key

/*
export const handlers = [
  http.get('https://api.smith.langchain.com/runs/batch', ({ params }) => {
    return HttpResponse.json({
      status: 'good'
    });
  }),
  http.post('https://api.smith.langchain.com/runs/batch', async ({ request }) => {
    const data = await request.json();
    window.emitter.emit('trace.log', {date: new Date(), json: data})
    return HttpResponse.json({
      status: 'good'
    });
  })
];

const worker = setupWorker(...handlers);
*/

import { defineComponent } from 'vue';
import { ref } from 'vue';

export default defineComponent({
  name: 'App',
  setup() {
    const store = useCounterStore();
    const base = BaseStore();

    const count = computed(() => store.counter);
    const doubleCountValue = computed(() => store.doubleCount);
    const incrementCount = () => store.increment(); // use action
    const decrementCount = () => store.counter--; // manipulate directly

    // Option 3: use destructuring to use the store in the template
    const { counter, doubleCount } = storeToRefs(store); // state and getters need "storeToRefs"
    const { increment } = store; // actions can be destructured directly
    let message = base.message;
    let subscription = base.subscription;
    let token = () => ref(base.token);
    let connected = computed(() => true);
    let streaming = computed(() => true);
    let isAuthenticated = computed(() => true);
    let loading = computed(() => false);

    // TODO: Move all core user, subscription, status, etc for entire app
    // into this component. It can monitor remote state and make it available to all components
    return {
      // Option 1: return the store directly and couple it in the template
      store,
      message,
      subscription,
      token,
      connected,
      streaming,
      loading,
      isAuthenticated,
      // Option 2: use the store in functions and compute the state to use in the template
      count,
      doubleCountValue,
      incrementCount,
      decrementCount,

      // Option 3: pass the destructed state, getters and actions to the template
      counter,
      increment,
      doubleCount
    };
  },
  async mounted() {
    console.log('Starting...');
/*
    setTimeout( () => {
      worker.start({
      serviceWorker: {
        url: '/mockServiceWorker.js'
      }, onUnhandledRequest: 'bypass'
    });
    },2000)
*/

  }
});


</script>
