import { defineStore } from 'pinia';

export interface StatusState {
    message: string;
    streaming: boolean;
    connected: boolean;
    python: boolean;
    version: string;
    token: string;
    subscription: string;
}

export interface StatusGetters {
    getMessage: string;
    getStreaming: boolean;
    getConnected: boolean;
    getVersion: string;
    getPython: boolean;
    getToken: string;
    getSubscription: string;
}

export type StatusActions = {
    setMessage: (payload: { message: string }) => Promise<void>;
    setStreaming: (payload: { streaming: boolean }) => Promise<void>;
    setConnected: (payload: { connected: boolean }) => Promise<void>;
    setPython: (payload: { python: boolean }) => Promise<void>;
    setToken: (payload: { token: string }) => Promise<void>;
    setSubscription: (payload: { subscription: string }) => Promise<void>;
}

export class StoreBaseClass implements StatusState, StatusGetters, StatusActions {
    getMessage!: StatusGetters['getMessage'];
    getStreaming!: StatusGetters['getStreaming'];
    getConnected!: StatusGetters['getConnected'];
    getPython!: StatusGetters['getPython'];
    getVersion!: StatusGetters['getVersion'];
    getToken!: StatusGetters['getToken'];
    getSubscription!: StatusGetters['getSubscription'];

    version!: StatusState['version'];
    message!: StatusState['message'];
    streaming!: StatusState['streaming'];
    connected!: StatusState['connected'];
    python!: StatusState['python'];
    token!: StatusState['token'];
    subscription!: StatusState['subscription'];

    setMessage!: (payload: { message: string }) => Promise<void>;
    setStreaming!: (payload: { streaming: boolean }) => Promise<void>;
    setConnected!: (payload: { connected: boolean }) => Promise<void>;
    setPython!: (payload: { python: boolean }) => Promise<void>;
    setToken!: (payload: { token: string }) => Promise<void>;
    setSubscription!: (payload: { subscription: string }) => Promise<void>;
}

export const BaseStore = defineStore('base', {
  state: () => ({
    token: '',
    user: {},
    authenticated: false,
    message: 'Ready',
    subscription: 'ec_free-USD-Monthly'
  }),

  actions: {
  }
});

// TODO: Add persistent properties for main app (i.e. not specific to a particular flow)
export const useCounterStore = defineStore('counter', {
  state: () => ({
    counter: 0
  }),

  getters: {
    doubleCount (state) {
      return state.counter * 2;
    }
  },

  actions: {
    increment () {
      this.counter++;
    }
  }
});
